import React, { useContext, useEffect, useRef, useState } from 'react';

import { useSearchParams } from 'next/navigation';

import { noop } from 'lodash';

import SpinnerCircle from '@/components/Spinner/SpinnerCircle';

import {
  checkAuth,
  loginWithCustomToken,
  onAuthStateChanged,
  signOut,
} from '@/lib/firebase/auth';
import { getUserCompanyId, loadUserDocument } from '@/lib/firebase/db/helpers';
import { UserDoc } from '@/lib/firebase/db/metaTypes';

import { reactAppLoginRoute, reactAppUrl } from '@/lib/env';
import log from '@/lib/logger';

interface AuthContextType {
  userDoc: UserDoc | null;
  isAuthenticated: boolean;
  isImpersonating: boolean;
  userCompanyId: string;
}

export const AuthContext = React.createContext<AuthContextType>({
  userDoc: null,
  isAuthenticated: false,
  isImpersonating: false,
  userCompanyId: '',
});

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [userDoc, setUserDoc] = useState<UserDoc | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isImpersonating, setIsImpersonating] = useState(false);
  const [userCompanyId, setUserCompanyId] = useState('');
  const [loading, setLoading] = useState(true);
  const initialized = useRef(false);
  const searchParams = useSearchParams();

  useEffect(() => {
    let unsubscribeDoc = noop;
    const unsubscribeAuth = onAuthStateChanged(async (authUser) => {
      log.info('onAuthStateChanged - User:', authUser);
      setIsAuthenticated(!!authUser);
      // Check if session cookie is set
      const customToken = await checkAuth();

      // When user is not logged in check if it can be authenticated with
      // session cookies or should be redirected to external login page
      if (!authUser && !initialized.current) {
        if (!!customToken) {
          // Use custom token to login
          await loginWithCustomToken(customToken);
        }
        // Check if redirect has been disabled by query params
        else if (searchParams.get('avoidRedirect') === null) {
          log.info('No token received, should redirect to external site');
          return redirectToLogin();
        }
      } else if (!!authUser) {
        // Check if session cookie is still valid.
        if (!customToken) {
          log.info(
            `Check auth didn't retrieve a new token, which means cookies were cleared, now should logout and redirect`
          );
          signOut().then(redirectToLogin);
        } else {
          // Merge user data from db into user object
          unsubscribeDoc = loadUserDocument(authUser, (userDocSnapshot) => {
            setUserDoc(userDocSnapshot || null);
            setUserCompanyId(getUserCompanyId(userDocSnapshot) || '');
            setIsImpersonating(authUser.uid !== userDocSnapshot.id);
          });
        }
      }

      setLoading(false);
      initialized.current = true;
    });

    return () => {
      unsubscribeAuth();
      unsubscribeDoc();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{ userDoc, isAuthenticated, userCompanyId, isImpersonating }}
    >
      {loading ? <FullScreenSpinner /> : isAuthenticated ? children : null}
    </AuthContext.Provider>
  );
};

function redirectToLogin() {
  // Set current url as query param to be redirected back once signed in
  const redirectToURL = window.location.href;
  const loginURL = `${reactAppUrl}${reactAppLoginRoute}?redirectTo=${redirectToURL}`;
  window.location.href = loginURL;
  return;
}

function FullScreenSpinner() {
  return (
    <div className="h-[100vh]">
      <div className="flex h-full justify-center">
        <SpinnerCircle />
      </div>
    </div>
  );
}
